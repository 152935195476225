import './Info.css'
import { Tooltip } from 'react-tooltip'
import icons from '../../../icons/icons'

const Info = function({data}) {
    const pstyle = {
        color:'white',
        textAlign:'left',
        fontSize:'1.2rem',
        textWrap:'nowrap',
        padding:'0'
    }

    return (
        <div className='info' data-tooltip-id={`conInfo${data.port.id}`}>
            {icons.info}
            <Tooltip id={`conInfo${data.port.id}`} style={{backgroundColor:'rgb(0,48,143)'}}>
                <p style={pstyle}>{`Здание: ${data.building.number}`}</p>
                <p style={pstyle}>{`Помещение: ${data.room.number}`}</p>
                <p style={pstyle}>{`Серверная стойка: ${data.server_rack.title}`}</p>
                <p style={pstyle}>{`Юнит: ${data.unit.uid} (${data.unit.side})`}</p>
                <p style={pstyle}>{`Оборудование: ${data.equipment.manufacturer} ${data.equipment.model}`}</p>
                <p style={pstyle}>{`Порт: ${data.port.uid} (${data.port.ip ? data.port.ip : 'null'})`}</p>
            </Tooltip>
        </div>
    )
}

export default Info