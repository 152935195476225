import './dashboard.css'
import Plate from '../plate/plate';
import Note from '../note/note';

const Dashboard = function() {
    const notes = ['','','','','','','','','','','','','','','','','','','','']

    return(
        <div className='dashboard'>
            {/* <p className='dashboard__title'>Dashboard</p> */}
            <div className='dashboard__plates'>
                <Plate category='Schemes total' data='3'/>
                <Plate category='Buildings total' data='15'/>
                <Plate category='Buildings connected' data='6'/>
                <Plate category='Users total' data='2'/>
            </div>
            <div className='dashboard__plot'>
                [ Plot area ]
            </div>
            <div className='dashboard__history'>
                <div className='dashboard__history__header'>
                    <p className='dashboard__history__header__id'>ID</p>
                    <p className='dashboard__history__header__user'>User</p>
                    <p className='dashboard__history__header__date'>Date</p>
                    <p className='dashboard__history__header__action'>Action</p>
                </div>
                <div className='dashboard__history__notes'>
                    {notes.map(note => (<Note id='1' user='admin' date='13.06.2024' action='Added new scheme "Scheme 0"'/>))}
                </div>
            </div>
        </div>
    );
}

export default Dashboard;
