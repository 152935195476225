import './note.css'

const Note = function(props) {
    return(
        <div className='note'>
            <p className='note__id'>{props.id}</p>
            <p className='note__user'>{props.user}</p>
            <p className='note__date'>{props.date}</p>
            <p className='note__action'>{props.action}</p>
        </div>
    );
}

export default Note;