import './plate.css'
import icons from '../../icons/icons';

const Plate = function(props) {
    return(
        <div className='plate'>
            {props.category.includes('Schemes') ? icons.dashboard.scheme : props.category.includes('Buildings') ? icons.dashboard.building : icons.dashboard.user}
            <div className='plate__data'>
                <p className='plate__data__category'>{props.category}</p>
                <p className='plate__data__value'>{props.data}</p>
            </div>
        </div>
    );
}

export default Plate;