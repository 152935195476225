import { useLocation,Navigate } from "react-router-dom";
import { useAuth } from "./useAuth";

const RequireAuth = function({children}) {
    const location = useLocation()
    const {user} = useAuth();

    if (!user) {
        return <Navigate to={'/loginPage'} state={{from:location}}/>
    }

    return children
}

export default RequireAuth