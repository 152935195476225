import { Outlet } from "react-router-dom";
import Header from "../header/header";
import Navbar from "../navbar/navbar";
import Modal from "../Modal/Modal";
import Navigation from "../Navigation/Navigation";
import NavigationLink from "../Navigation/NavigationLink/NavigationLink";
import { useState } from "react";
import { Toaster } from "sonner";

const Layout = function (props) {
    const [modalActive,setModalActive] = useState(false)

    return(
        <>
            <Header toggleNavbar={props.toggleNavbar} navbarHidden={props.navbarHidden}/>
            <Navbar navbarHidden={props.navbarHidden} toggleNavbar={props.toggleNavbar}/>
            <Outlet/>
            <Modal active={modalActive} setActive={setModalActive}/>
            <Toaster richColors toastOptions={{style:{fontSize:'20px'}}}/>
        </>
    );
}

export default Layout;