import './DevicePreview.css'
import { useState,useEffect } from 'react'
import axios from 'axios'
import SocketPreview from './SocketPreview/SocketPreview'
import icons from '../../icons/icons'

const DevicePreview = function({id,onselect,onmultipleselect}) {
    const axiosInstance = axios.create({
        headers: {
          'Content-Type': 'application/json',
          'Authorization':`Token ${localStorage.getItem('authToken')}`
        }
    })

    const [deviceData,setDeviceData] = useState(null)
    const [ports,setPorts] = useState(null)
    const [sfpTemplates,setSfpTemplates] = useState(null)
    const [selectedSockets,setSelectedSockets] = useState([])

    useEffect(() => {
        let url1 = `/core_api/equipment/${id}/`
        let url2 = '/core_api/port/'
        let url3 = '/core_api/sfp_template/'

        Promise.all([
            axiosInstance.get(url1),
            axiosInstance.get(url2,{params:{filter_equipment:id,order_by:'uid'}}),
            axiosInstance.get(url3)
        ])
        .then(([data1,data2,data3]) => {
            setDeviceData(data1.data)
            setPorts(data2.data)
            setSfpTemplates(data3.data.results)
        })

    }, [id])

    const buildEquipmentStructure = function(data) {
        let areaId = 1
        let socketId = 1

        function spawnSockets(num) {
            let arr = []
            
            for (let i = 0;i < num;i++) {
                let port = ports ? ports.find(item => item.uid === socketId) : ''

                arr.push(
                    <SocketPreview
                        id={port.id}
                        uid={socketId}
                        data={{...port}}
                        eqType={deviceData.template.type}
                        sfps={sfpTemplates.map(item => item.name)}
                        selected={selectedSockets.includes(port.id) ? true : false}
                        onselect={selectSockets}
                        onAddSfp={insertSfp}
                        onDelSfp={deleteSfp}
                    />
                )
                socketId += 1
            }

            return arr
        }

        let subArr = []
        let subSubArr = []
        for (let temp in data) {
            subArr.push(data[temp])
        }

        subSubArr = subArr.sort((a,b) => a.unit[0] - b.unit[0])

        function spawnAreas(data) {
            let arr = []

            for (let temp in data) {
                arr.push(
                    <div 
                        className='device-preview__body__socket-area'
                        style={{
                            gridTemplateColumns:`repeat(${data[temp].count/2},1fr)`,
                            gridArea:`area${areaId}`,
                            gridTemplateRows:`repeat(${data[temp].lines},1fr)`,
                            gridRow:`${data[temp].unit[0]}`
                        }}
                    >
                        {spawnSockets(data[temp].count)}
                    </div>
                )
                areaId += 1
            }

            return arr
        }

        return spawnAreas(subSubArr)
    }

    const generateTemplateAreas = function(data) {
        let areaId = 1
        let tempAreas = []
        let subArr = []
        let subSubArr = []
        let units = []

        for (let temp in data) {
            subArr.push(data[temp])
        }

        units = [...new Set(subArr.map(item => item.unit[0]))]
        
        for (let i = 0;i < units.length;i++) {
            subSubArr.push(subArr.filter(item => item.unit[0] === units[i]))
        }

        let targetLength = 1

        for (let i in subSubArr) {
            let arr = []
            for (let j in subSubArr[i]) {
                arr.push(`area${areaId}`)
            }
            if (arr.length > targetLength) {
                targetLength = arr.length
            }
        }

        for (let i in subSubArr) {
            let arr = []
            for (let j in subSubArr[i]) {
                arr.push(`area${areaId}`)
                areaId += 1
            }
            if (arr.length < targetLength) {
                for (let i = 0; i < targetLength-1; i++) {
                    arr.push(arr[arr.length - 1]);
                }
            }
            tempAreas.push(arr)
        }

        return tempAreas.map(item => `"${item.join(' ')}"`).join(' ')
    }

    const selectSockets = function(id) {
        if (selectedSockets.includes(id)) {
            if (deviceData.template.type !== 'Пассивное оборудование') {
                setSelectedSockets(prevValue => prevValue.filter(item => item !== id))
                onselect(null)
            }
            else {
                setSelectedSockets(prevValue => prevValue.filter(item => item !== id))
                onmultipleselect(selectedSockets)
            }
        }
        else {
            if (deviceData.template.type !== 'Пассивное оборудование') {
                setSelectedSockets([id])
                onselect(id)
            }
            else {
                setSelectedSockets(prevValue => [...prevValue,id])
                onmultipleselect([...selectedSockets,id])
            }
        }
    }

    const insertSfp = async function(id,name) {
        if (name) {
            let url = `/core_api/port/${id}/add_sfp/`
            let sfpId = sfpTemplates.find(item => item.name === name).id

            let data = {
                sfp_template_id:sfpId
            }

            let updateData = async function(data) {
                setPorts(data)
            }

            await axiosInstance.put(url,data)
                .then(data => updateData(data.data))
                .catch(e => console.log(e))
        }
    }

    const deleteSfp = async function(portId) {
        let url = `/core_api/port/disconnect_sfp/`

        let data = {
            port_list:[portId],
            equipment_id:id
        }

        await axiosInstance.put(url,data)
            .then(data => setPorts(data.data))
            .catch(e => console.log(e))
    }

    return (
        <div className='device-preview'>
            <div className='device-preview__title'>
                {deviceData ? `${deviceData.template.manufacturer} ${deviceData.template.model}` : 'Loading...'}
            </div>
            <div 
                className='device-preview__body'
                style={!ports && !deviceData ? 
                    {} : 
                    {
                        gridTemplateRows:`repeat(${deviceData.template.number_of_units},1fr)`,
                        gridTemplateAreas:generateTemplateAreas(deviceData.count_port_template_dict)
                    }
                }
            >
                {!ports && !deviceData && !sfpTemplates ? 
                        'Loading...' : 
                        buildEquipmentStructure(deviceData.count_port_template_dict)
                }
            </div>
        </div>
    )
}

export default DevicePreview