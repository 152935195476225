import { useState } from 'react';
import './loginPage.css'
import icons from '../../icons/icons';
import axios from 'axios';
import { toast } from 'sonner';
import { useNavigate,useLocation } from 'react-router-dom';
import { useAuth } from '../hoc/useAuth';

const LoginPage = function(props) {
    const axiosInstance = axios.create({
        headers: {
          'Content-Type': 'multipart/form-data',
        }
    })

    const navigate = useNavigate()
    const location = useLocation()
    const {signin} = useAuth()

    const fromPage = location.state?.from?.pathname || '/'

    const [isLogging,setIsLogging] = useState(true)
    const [username,setUsername] = useState('admin')
    const [password,setPassword] = useState('12345678')
    const [passwordConfirm,setPasswordConfirm] = useState(null)

    const toggleLogging = function() {
        setIsLogging(!isLogging)
    }

    const fillUsername = function(event) {
        setUsername(event.target.value)
    }

    const fillPassword = function(event) {
        setPassword(event.target.value)
    }

    const fillPasswordConfirm = function(event) {
        setPasswordConfirm(event.target.value)
    }

    function getCookie(name) {
        let cookieValue = null;
        if (document.cookie && document.cookie !== '') {
            const cookies = document.cookie.split(';');
            for (let i = 0; i < cookies.length; i++) {
                const cookie = cookies[i].trim();
                // Проверяем, начинается ли cookie с нужного имени
                if (cookie.substring(0, name.length + 1) === (name + '=')) {
                    cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                    break;
                }
            }
        }
        return cookieValue;
    }

    const signIn = async function() {
        const url = '/core_api/auth/token/login/'

        const csrftoken = getCookie('csrftoken');

        const data = {
            password:password,
            username:username
        }

        await axiosInstance.post(url,data)
            .then(function (response) {
                localStorage.setItem('authToken',response.data.auth_token)
                signin(username, () => navigate(fromPage, {replace:true}))
                toast.success(
                    <div className='toast-message--success'>{icons.success} Вы успешно вошли как {username}!</div>,
                    {duration:3000,position:'bottom-left'}
                )
            })
            .catch(function (error) {
                if (error.response.status === 400) {
                    toast.error(`Wrong username or password, please try again.`,{duration:5000,position:'bottom-left'})
                }
            });
    }

    const handleKeyDown = function(event) {
        if (event.key === 'Enter') {
            signIn()
        }
    }

    return(
        <div className={!props.navbarHidden ? 'login-page' : 'login-page login-page--blured'} onKeyDown={handleKeyDown} autoFocus>
            {icons.login.planchet}
            <p className='login-page__header'>{isLogging ? 'Logging In' : 'Signing Up'}</p>
            <input type='text' placeholder='<username>' className='login-page__login' onChange={fillUsername} autoFocus/>
            <input type='password' placeholder='<password>' className='login-page__password' onChange={fillPassword}/>
            {!isLogging ? <input type='password' placeholder='<confirm password>' className='login-page__confirm-password' onChange={fillPasswordConfirm}/> : ''}
            {isLogging ? <p className='login-page__accord'>Still don't have an account?
                <a onClick={toggleLogging}>Sign up here!</a>
            </p> : <p className='login-page__accord'>Already have an account?
                <a onClick={toggleLogging}>Log In!</a>
            </p>}
            <button className='login-page__confirm' onClick={signIn}>
                {icons.login.enter}
                {isLogging ? 'Log In' : 'Sign Up'}
            </button>
        </div>
    );
}

export default LoginPage;