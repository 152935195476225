import './App.css';
import { useState } from 'react';
import HomePage from './components/homePage/homePage';
import Schemes from './components/Schemes/schemes';
import Cabinets from './components/Cabinets/cabinets';
import Racks from './components/Racks/racks';
import RackDetailed from './components/Rack/RackDetailed/RackDetailed';
import LoginPage from './components/loginPage/loginPage';
import { Routes, Route } from 'react-router-dom';
import Layout from './components/Layout/layout';
import SchemeDetailed from './components/SchemeDetailed/schemeDetailed';
import Device from './components/Device/Device';
import PatchPanel from './components/PatchPanel/PatchPanel';
import UserList from './components/userList/UserList';
import RequireAuth from './components/hoc/RequireAuth';
import { AuthProvider } from './components/hoc/AuthProvider';

function App() {
  const [navbarHidden,setNavbarHidden] = useState(false)

  const toggleNavbar = function() {
    setNavbarHidden(!navbarHidden)
  }

  return (
    <div className="App">
      <AuthProvider>
        <Routes>
          <Route path='/' element={<Layout toggleNavbar={toggleNavbar} navbarHidden={navbarHidden}/>}>
            <Route index element={<HomePage navbarHidden={navbarHidden}/>}/>
            <Route path='schemes' element={<RequireAuth><Schemes navbarHidden={navbarHidden}/></RequireAuth>} state={{description:{title:'Схемы'}}}/>
            <Route path='schemes/:id/:name/:status' element={<SchemeDetailed navbarHidden={navbarHidden}/>}/>
            <Route path='cabinets/:id/:status' element={<Cabinets navbarHidden={navbarHidden}/>}/>
            <Route path='racks/:id/:status' element={<Racks />}/>
            <Route path='rack/:id' element={<RackDetailed />}/>
            <Route path='device/:id' element={<Device />}/>
            <Route path='patch-panel/:id' element={<PatchPanel />}/>
            <Route path='loginPage' element={<LoginPage navbarHidden={navbarHidden}/>}/>
            <Route path='userList/:id' element={<UserList />}/>
          </Route>
        </Routes>
      </AuthProvider>
    </div>
  );
}

export default App;