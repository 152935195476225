import './Equipment.css'
import { useState } from 'react'

const Equipment = function({id,type,model,manufacturer,ports,units,power,aboveTarget,passDraggingElement,addEquipment,delEquipment}) {
    const [showInfo,setShowInfo] = useState(false)
    const [isDragging,setIsDragging] = useState(false)

    const toggleDrag = function() {
        setIsDragging(true)
    }

    const toggleShowInfo = function() {
        setShowInfo(prevValue => !prevValue)
    }

    const handleDragStart = function() {
        passDraggingElement({id:id,units:units})
    }

    const handleDragEnd = function(e) {
        e.preventDefault()
        if (aboveTarget === false) {
            passDraggingElement(null)
        }
        else {
            addEquipment()
        }
    }

    const deleteEquipment = function() {
        delEquipment(Number(id))
    }

    return (
        <div 
            className='device-equipment'
            draggable
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
        >
            <div className="device-equipment__body">
                <div className='device-equipment__body__logo'>{type === 'Коммутатор' ? 'К' : type === 'Сервер' ? 'С' : type === 'Пассивное оборудование' ? 'ПО' : '?'}</div>
                {manufacturer}
                <div className='device-equipment__body__actions'>
                    <svg className={`device-equipment__body__actions__action`} onClick={deleteEquipment} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/></svg>
                    <svg 
                        className={`device-equipment__body__actions__action ${showInfo ? 'info-hidden' : 'info-shown'}`}
                        onClick={toggleShowInfo} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M233.4 105.4c12.5-12.5 32.8-12.5 45.3 0l192 192c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L256 173.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l192-192z"/>
                    </svg>
                </div>
            </div>
            
            <div className={!showInfo ? 'device-equipment__info' : 'device-equipment__info device-equipment__info--show'}
                style={{zIndex:900-id}}
            >
                <p className='device-equipment__info__parameter'><div>Тип</div> {type}</p>
                <p className='device-equipment__info__parameter'><div>Модель</div> {model}</p>
                <p className='device-equipment__info__parameter'><div>Кол-во портов</div> {ports} шт.</p>
                <p className='device-equipment__info__parameter'><div>Кол-во юнитов</div> {units} ед.</p>
                <p className='device-equipment__info__parameter'><div>Мощность</div> {power} Вт</p>
            </div>
        </div>
    )
}

export default Equipment