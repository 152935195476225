import './Socket.css'
import { useState,useRef,useEffect } from 'react'
import icons from '../../icons/icons'

const Socket = function({ socketData,data,onselect,onconnect,onDelSfp,onDisconnect }) {
    const [showMenu,setShowMenu] = useState(false)
    const [selected,setSelected] = useState(false)

    const rootRef = useRef(null);
    const refConnect = useRef(null);

    useEffect(() => {
        const handleClick = (event) => {
            const { target } = event;
            if (!rootRef.current?.contains(target)) {
                setShowMenu(false);
            }
        };
        window.addEventListener('click', handleClick);
        window.addEventListener('contextmenu', handleClick);
    }, []);

    const disableMenu = function(e) {
        e.preventDefault()
        if (e.target === rootRef.current) {
            setShowMenu(true)
        }
    }

    const select = function(e) {
        if (e.target === rootRef.current) {
            if (selected === false) {
                setSelected(true)
                onselect(Number(socketData.uid),true)
            }
            else {
                setSelected(false)
                onselect(Number(socketData.uid),false)
            }
        }
        else if (e.target === refConnect.current) {
            setShowMenu(false)
            onconnect(Number(socketData.id))
        }
    }

    const unplugSfp = function() {
        setShowMenu(false)
        onDelSfp(socketData.id)
    }

    const disconnect = function() {
        setShowMenu(false)
        onDisconnect(socketData.id)
    }

    return (
        <div 
            className={selected ? 'socket socket--selected' : 'socket'} 
            onClick={select} 
            ref={rootRef} 
            onContextMenu={disableMenu}
            style={{backgroundColor:Object.keys(data.connection).length > 0 || Object.keys(data.connection_pigtail).length > 0 ? 'lightgreen' : 'white'}}
        >
            {icons.device.socket}
            <p className='socket__title'>{socketData.uid}</p>
            {socketData.eqType !== 'Пассивное оборудование' ? 
                <p className='socket__status'>{socketData.state}</p> : 
                ''
            }
            <div className={showMenu ? "socket__menu" : "socket__menu socket__menu--hidden"}>
                {socketData.eqType !== 'Пассивное оборудование' ? 
                    <>
                        <div className="socket__menu__option" ref={refConnect}>
                            <div className="socket__menu__option__icon">{icons.connection}</div>
                            <div className="socket__menu__option__title">Подключение</div>
                        </div>
                        <div className="socket__menu__option" onClick={unplugSfp}>
                            <div className="socket__menu__option__icon">{icons.edit}</div>
                            <div className="socket__menu__option__title">Извлечь SFP</div>
                        </div>
                        <div className="socket__menu__option">
                            <div className="socket__menu__option__icon">{icons.edit}</div>
                            <div className="socket__menu__option__title">Изменить MAC</div>
                        </div>
                        <div className="socket__menu__option">
                            <div className="socket__menu__option__icon">{icons.edit}</div>
                            <div className="socket__menu__option__title">Изменить VLAN</div>
                        </div>
                        <div className="socket__menu__option">
                            <div className="socket__menu__option__icon">{icons.edit}</div>
                            <div className="socket__menu__option__title">Изменить IP</div>
                        </div>
                        <div className="socket__menu__option" onClick={disconnect}>
                            <div className="socket__menu__option__icon">{icons.disconnect}</div>
                            <div className="socket__menu__option__title">Отключить</div>
                        </div>
                    </> : 
                    <>
                        <div className="socket__menu__option" ref={refConnect}>
                            <div className="socket__menu__option__icon">{icons.connection}</div>
                            <div className="socket__menu__option__title">Подключение</div>
                        </div>
                        <div className="socket__menu__option" onClick={disconnect}>
                            <div className="socket__menu__option__icon">{icons.disconnect}</div>
                            <div className="socket__menu__option__title">Отключить</div>
                        </div>
                    </>
                }
                
            </div>
        </div>
    )
}

export default Socket