import './UserList.css'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import icons from '../../icons/icons'
import { useEffect, useState } from 'react'
import Modal from '../Modal/Modal'
import { toast } from 'sonner'

const UserList = function() {
    const [users,setUsers] = useState(null)
    const [availableUsers,setAvailableUsers] = useState(null)
    const [schemeName,setSchemeName] = useState(null)
    const [action,setAction] = useState('addUser')
    const [modalActive,setModalActive] = useState(false)
    const [userToDelete,setUserToDelete] = useState('')

    const {id} = useParams()
    const navigate = useNavigate()

    const axiosInstance = axios.create({
        headers: {
          'Content-Type': 'application/json',
          'Authorization':`Token ${localStorage.getItem('authToken')}`
        }
    })

    useEffect(() => {
        const fetchData = async function() {
            let url = `/core_api/access/`
            let url1 = `/core_api/scheme/${id}/`
            let url2 = '/core_api/users_list/'

            await axiosInstance.get(url,{params:{filter_scheme_id:id}})
                .then(data => setUsers(data.data.results))
                .catch(e => console.log(e))

            await axiosInstance.get(url1)
                .then(data => setSchemeName(data.data.title))
                .catch(e => console.log(e))

            await axiosInstance.get(url2,{params:{scheme_id:id}})
                .then(data => setAvailableUsers(data.data))
                .catch(e => console.log(e))
        }

        fetchData()
    }, [])

    const addUser = async function(name,role) {
        let url = '/core_api/access/'

        let userId = availableUsers.find(item => item.username === name).id
        let data = {
            scheme_id:id,
            user_id:userId,
            role:role
        }

        await axiosInstance.post(url,data)
            .then(data => {
                setUsers(prevValue => [...prevValue,data.data])
                setAvailableUsers(prevValue => prevValue.filter(item => item.username !== name))
                toast.success(
                    <div className='toast-message--success'>{icons.success} Пользователь {name} был успешно добавлен в схему!</div>,
                    {duration:5000,position:'bottom-left'}
                )
            })
            .catch(e => console.log(e))
    }

    const confirmDeletion = function(e) {
        setAction('delUser')
        setUserToDelete(e.target.getAttribute('id'))
        setModalActive(true)
    }

    const delUser = async function(state) {
        if (state) {
            let userId = users.find(item => item.user.username === userToDelete).id
            let url = `/core_api/access/${userId}/`

            await axiosInstance.delete(url)
                .then(data => {
                    setUsers(prevValue => prevValue.filter(item => item.user.username !== userToDelete))
                    setAvailableUsers(prevValue => [...prevValue,{id:userId,username:userToDelete}])
                    setAction('addUser')
                    toast.success(
                        <div className='toast-message--success'>{icons.success} Пользователь {userToDelete} был успешно удален из схемы!</div>,
                        {duration:5000,position:'bottom-left'}
                    )
                    setUserToDelete('')
                })
                .catch(e => console.log(e))
        }
        else {
            setAction('addUser')
            setUserToDelete('')
        }
    }

    return (
        <div className='user-list'>
            <div className='user-list__title'>
                {`Пользователи схемы ${schemeName ? schemeName : ''}:`}
            </div>
            <div className='user-list__wrapper'>
                <div className='user-list__wrapper__add' onClick={()=>setModalActive(prevValue => !prevValue)}>
                    {icons.user_plus}Добавить
                </div>
                {users ? users.map(item => 
                    <div id={item.user.username} className='user-list__wrapper__item'>
                        {item.role === 'Creator' ? '' : <div id={item.user.username} className='user-list__wrapper__item__delete' onClick={confirmDeletion}>{icons.squareMinus}</div>}
                        <div className='user-list__wrapper__item__roles'>{item.role === 'Creator' ? icons.crown : item.role === 'Change' ? icons.edit : icons.viewing}</div>
                        <div className='user-list__wrapper__item__icon'>{icons.user}</div>
                        <div className='user-list__wrapper__item__title'>{item.user.username}</div>
                    </div>
                ) : ''}
            </div>
            <Modal
                active={modalActive}
                setActive={setModalActive}
                action={action}
                subData={availableUsers ? availableUsers : []}
                axiosInstance={axiosInstance}
                getResult={action === 'addUser' ? addUser : delUser}
            />
        </div>
    )
}

export default UserList