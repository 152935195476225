import './SfpTemplate.css'
import { useState } from 'react'
import icons from '../../icons/icons'

const SfpTemplate = function({id,name,manufacturer,type_port,speed,line_type,delTemplate,onplug}) {
    const [showInfo,setShowInfo] = useState(false)

    const toggleShowInfo = function() {
        setShowInfo(!showInfo)
    }

    const deleteSfpTemplate = function() {
        delTemplate(Number(id))
    }

    const plugSfp = function() {
        onplug(Number(id))
    }

    return (
        <div className='sfp-template'>
            {name}
            <div className="sfp-template__thumb" onClick={toggleShowInfo}>
                <svg style={showInfo ? {transform:`rotateX(180deg)`} : {}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"/></svg>
            </div>
            <div className="sfp-template__delete" onClick={deleteSfpTemplate}>{icons.squareMinus}</div>
            <div className="sfp-template__plug" onClick={plugSfp}>{icons.plug}</div>
            <div 
                className={showInfo ? "sfp-template__info" : "sfp-template__info sfp-template__info--hidden"}
                style={{zIndex:`${1000-id}`}}
            >
                <div className='sfp-template__info__param'><p>Производитель</p> {manufacturer}</div>
                <div className='sfp-template__info__param'><p>Тип порта</p> {type_port}</div>
                <div className='sfp-template__info__param'><p>Скорость</p> {speed}</div>
                <div className='sfp-template__info__param'><p>Мод</p> {line_type}</div>
            </div>
        </div>
    )
}

export default SfpTemplate