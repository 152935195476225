import './racks.css'
import { useParams, useNavigate } from 'react-router-dom';
import Rack from '../Rack/rack';
import axios from 'axios';
import { useState, useEffect } from 'react';
import icons from '../../icons/icons';
import { toast } from 'sonner';
import Modal from '../Modal/Modal';

const Racks = function(props) {
    const {id,status} = useParams()
    const navigate = useNavigate()

    const goBack = () => navigate(-1)

    const [modalActive,setModalActive] = useState(false)
    const [action,setAction] = useState('addRack')
    const [rackId,setRackId] = useState(null)
    const [racks,setRacks] = useState([])

    const url = '/core_api/server_rack/'

    const axiosInstance = axios.create({
        headers: {
          'Content-Type': 'application/json',
          'Authorization':`Token ${localStorage.getItem('authToken')}`
        }
    })

    useEffect(() => {
        axiosInstance.get(url,{params:{filter_room_id:id}})
            .then(data => setRacks(data.data))
            .catch(e => console.log(e))
    }, [])

    const showModal = function() {
        setModalActive(true)
    }

    const addRack = async function(title,units,power) {
        let data = {
            number_of_units:units,
            room_id:id,
            title:title,
            max_power:power
        }

        let updateRacks = function(data) {
            setRacks([...racks,{
                id:data.data.id,
                title:data.data.title,
                number_of_units:data.data.number_of_units,
                max_power:data.data.max_power
            }])
            toast.success(
                <div className='toast-message--success'>{icons.success} Серверная стойка {data.data.title} была успешно создана!</div>,
                {duration:5000,position:'bottom-left'}
            )
        }

        await axiosInstance.post(url,data)
            .then(data => updateRacks(data))
            .catch(e => console.log(e))
    }

    const confirmDeletion = function(id) {
        setRackId(id)
        setAction('delRack')
        setModalActive(true)
    }

    const deleteRack = async function(state) {
        if (state === true) {
            let url = `/core_api/server_rack/${rackId}/`
            let rackTitle = racks.find(item => item.id === rackId).title

            let updateRacks = function() {
                setRacks(racks => racks.filter(item => item.id !== rackId))
                setRackId(null)
                setAction('addRack')
                toast.success(
                    <div className='toast-message--success'>{icons.success} Серверная стойка {rackTitle} была успешно удалена!</div>,
                    {duration:5000,position:'bottom-left'}
                )
            }

            await axiosInstance.delete(url)
                .then(data => updateRacks())
                .catch(e => console.log(e))
        }
        else {
            setRackId(null)
            setAction('addRack')
        }
    }

    return(
        <div className='racks'>
            <p className='racks__header'>Серверные стойки:</p>
            <div className='racks__wrapper'>
                <div className="racks__wrapper__add-new" onClick={showModal}>
                    {icons.squarePlus} Добавить
                </div>
                {racks.map(rack => (
                    <Rack 
                        id={rack.id} 
                        name={rack.title} 
                        length={rack.number_of_units}
                        delRack={confirmDeletion}
                    />
                ))}
            </div>
            <Modal
                active={modalActive}
                setActive={setModalActive}
                action={action}
                getResult={action === 'addRack' ? addRack : deleteRack}
            />
        </div>
    );
}

export default Racks;