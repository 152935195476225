import './navbar.css'
import { NavLink } from 'react-router-dom';

const Navbar = function(props) {
    return(
        <div className={!props.navbarHidden ? 'navbar navbar--hidden' : 'navbar'}>
            <ul>
                <li>
                    <NavLink to='/' onClick={props.toggleNavbar} className={({isActive}) => (isActive ? 'link--active' : '')}>Home</NavLink>
                </li>
                <li>
                    <NavLink to='/schemes' state={{description:'Схемы'}} onClick={props.toggleNavbar} className={({isActive}) => (isActive ? 'link--active' : '')}>Schemes</NavLink>
                </li>
            </ul>
      </div>
    );
}

export default Navbar;