import { useRef, useState, useEffect } from 'react'
import './SocketPreview.css'
import { Tooltip } from 'react-tooltip'
import icons from '../../../icons/icons'

const SocketPreview = function({id,uid,data,eqType,sfps,selected,onselect,onAddSfp,onDelSfp}) {
    const [menuVisible,setMenuVisible] = useState(false)
    const [subMenuVisible,setSubMenuVisible] = useState(false)

    const rootRef = useRef(null)

    useEffect(() => {
        const handleClick = (event) => {
            const { target } = event;
            if (!rootRef.current?.contains(target)) {
                setMenuVisible(false);
            }
        };
        window.addEventListener('click', handleClick);
    }, []);

    const setSelected = function(e) {
        if (e. target === rootRef.current) {
            onselect(id)
        }
    }

    const pstyle = {
        color:'white',
        textAlign:'left',
        fontSize:'1.2rem'
    }

    const showMenu = function(e) {
        e.preventDefault()
        setMenuVisible(menuVisible => !menuVisible)
    }

    const showSubMenu = function() {
        if (subMenuVisible === true) {
            setSubMenuVisible(false)
        }
        else {
            setSubMenuVisible(true)
        }
    }

    const plugSfp = function(e) {
        setMenuVisible(false)
        onAddSfp(id,e.target.innerHTML)
    }

    const unplugSfp = function() {
        setMenuVisible(false)
        onDelSfp(id)
    }

    return (
        <div 
            className={selected ? 'socket-preview socket-preview--selected' : 'socket-preview'} 
            onClick={setSelected}
            style={{backgroundColor:Object.keys(data.connection).length > 0 || Object.keys(data.connection_pigtail).length > 0 ? 'lightgreen' : 'transparrent'}}
            data-tooltip-id={`info${id}`}
            data-tooltip-place='top'
            onContextMenu={showMenu}
            ref={rootRef}
        >
            {eqType !== 'Пассивное оборудование' ? 
                <div className='socket-preview__state'>
                    {data.type.modular === false ? '' : data.sfp ? icons.circleFilled : icons.circleHole}
                </div> : 
                ''
            }
            <Tooltip id={`info${id}`} style={{backgroundColor:'rgb(0,48,143)'}}>
                <p style={pstyle}>{`Тип: ${data.type.name}`}</p>
                <p style={pstyle}>{`Скорость: ${data.sfp ? data.sfp.speed[0] : data.speed.join('/')}`}</p>
                {eqType !== 'Пассивное оборудование' ? 
                    <>
                        <p style={pstyle}>{`Модульный: ${data.type.modular === true ? 'Да' : 'Нет'}`}</p>
                        {
                            data.type.modular ? 
                                <p style={pstyle}>{`SFP: ${data.sfp ? data.sfp.name : 'Нет'}`}</p> : 
                                ''
                        } 
                    </> : 
                    ''
                }
            </Tooltip>
            {uid}
            {eqType !== 'Пассивное оборудование' ? 
                <div className={menuVisible === true ? 'socket-preview__menu' : 'socket-preview__menu socket-preview__menu--hidden'}>
                    <div className='socket-preview__menu__option' onMouseEnter={showSubMenu} onMouseLeave={showSubMenu}>
                        Подключить SFP {icons.arrowRight}
                        <div className={subMenuVisible === true ? 'socket-preview__menu__option__submenu' : 'socket-preview__menu__option__submenu socket-preview__menu__option__submenu--hidden'}>
                            {sfps.map(item => 
                                <div className='socket-preview__menu__option__submenu__option' onClick={plugSfp}>
                                    {item}
                                </div>
                            )}
                        </div>
                    </div>
                    <div className='socket-preview__menu__option' onClick={unplugSfp}>
                        Извлечь SFP
                    </div>
                </div> : 
                ''
            }
        </div>
    )
}

export default SocketPreview