import './buildingScheme.css'
import icons from '../../icons/icons';
import axios from 'axios';
import { useState,useEffect } from 'react';

function BuildingScheme({id,number}) {
    const url = `/core_api/room/`

    const axiosInstance = axios.create({
        headers: {
          'Content-Type': 'application/json',
          'Authorization':`Token ${localStorage.getItem('authToken')}`
        }
    })

    const [roomCount,setRoomCount] = useState(null)

    useEffect(() => {
        const fetchRoomData = async function() {
            await axiosInstance.get(url,{params:{filter_building_id:id}})
                .then(data => setRoomCount(data.data.results.length))
                .catch(e => console.log(e))
        }
        fetchRoomData()
    }, [])

    return(
        <div id={id} className='building-scheme'>
            {icons.bs.building}
            <p className='building-scheme__title'>{number}</p>
            <div className="building-scheme__room-count">{roomCount ? roomCount : '0'}</div>
        </div>
    );
}

export default BuildingScheme;