import { useParams,useNavigate } from 'react-router-dom'
import './PatchPanel.css'
import axios from 'axios'
import { useState,useEffect } from 'react'
import Socket from '../Socket/Socket'
import icons from '../../icons/icons'
import Modal from '../Modal/Modal'
import { toast } from 'sonner'
import Info from '../Socket/Info/Info'

const PatchPanel = function() {
    const navigate = useNavigate()
    const goBack = function() {
        navigate(-1)
    }

    const {id} = useParams()
    const url1 = `/core_api/equipment/${id}/`
    const url2 = '/core_api/port/'

    const [modalActive,setModalActive] = useState(false)
    const [action,setAction] = useState('delSfpTemplate')
    const [data,setData] = useState(null)
    const [ports,setPorts] = useState(null)
    const [portId,setPortId] = useState(null)
    const [selectedSockets,setSelectedSockets] = useState([])

    const axiosInstance = axios.create({
        headers: {
          'Content-Type': 'application/json',
          'Authorization':`Token ${localStorage.getItem('authToken')}`
        }
    })

    useEffect(() => {
        async function fetchEquipmentData() {
            await axiosInstance.get(url1)
                .then(data => setData(data))
                .catch(e => console.log(e))

            await axiosInstance.get(url2,{params:{filter_equipment:id,order_by:'uid'}})
                .then(data => setPorts(data))
                .catch(e => console.log(e))
        }

        fetchEquipmentData()
    }, [])

    const buildEquipmentStructure = function(ddata) {
        let areaId = 1
        let socketId = 1

        function spawnSockets(num) {
            let arr = []
            
            for (let i = 0;i < num;i++) {
                let port = ports ? ports.data.find(item => item.uid === socketId) : ''

                arr.push(
                    <Socket 
                        socketData={{
                            id:port.id,
                            uid:socketId,
                            state:port.type.modular === false ? '' : (port.sfp !== null ? icons.circleFilled : icons.circleHole),
                            eqType:data.data.template.type
                        }}
                        data={{...port}}
                        onselect={selectSocket}
                        onconnect={setupConnection}
                    />
                )
                socketId += 1
            }

            return arr
        }

        let subArr = []
        let subSubArr = []
        for (let temp in ddata) {
            subArr.push(ddata[temp])
        }

        subSubArr = subArr.sort((a,b) => a.unit[0] - b.unit[0])

        function spawnAreas(data) {
            let arr = []

            for (let temp in data) {
                arr.push(
                    <div 
                        className='patch-panel__wrapper__socket-area'
                        style={{
                            gridTemplateColumns:`repeat(${data[temp].count/2},1fr)`,
                            gridArea:`area${areaId}`,
                            gridTemplateRows:`repeat(${data[temp].lines},1fr)`,
                            gridRow:`${data[temp].unit[0]}`
                        }}
                    >
                        {spawnSockets(data[temp].count)}
                    </div>
                )
                areaId += 1
            }

            return arr
        }

        return spawnAreas(subSubArr)
    }

    const generateTemplateAreas = function(data) {
        let areaId = 1
        let tempAreas = []
        let subArr = []
        let subSubArr = []
        let units = []

        for (let temp in data) {
            subArr.push(data[temp])
        }

        units = [...new Set(subArr.map(item => item.unit[0]))]
        
        for (let i = 0;i < units.length;i++) {
            subSubArr.push(subArr.filter(item => item.unit[0] === units[i]))
        }

        let targetLength = 1

        for (let i in subSubArr) {
            let arr = []
            for (let j in subSubArr[i]) {
                arr.push(`area${areaId}`)
            }
            if (arr.length > targetLength) {
                targetLength = arr.length
            }
        }

        for (let i in subSubArr) {
            let arr = []
            for (let j in subSubArr[i]) {
                arr.push(`area${areaId}`)
                areaId += 1
            }
            if (arr.length < targetLength) {
                for (let i = 0; i < targetLength-1; i++) {
                    arr.push(arr[arr.length - 1]);
                }
            }
            tempAreas.push(arr)
        }

        return tempAreas.map(item => `"${item.join(' ')}"`).join(' ')
    }

    const selectSocket = function(uid,state) {
        let id = ports.data.find(item => item.uid === uid).id
        if (state === true) {
            setSelectedSockets([...selectedSockets,id])
        }
        else {
            setSelectedSockets(selectedSockets.filter(item => item !== id))
        }
    }

    const checkSelectedSockets = function(sockets) {
        let arr = sockets.map(item => ports.data.find(port => port.id === item).type.modular)

        return arr.includes(false) ? false : true
    }

    const setupConnection = function(id) {
        setAction('connection')
        setPortId(id)
        setModalActive(true)
    }

    const connectSockets = async function(connectionData) {
        let url = '/core_api/port/connection_pigtail_list/'

        let data = {
            pigtail_list:selectedSockets,
            connection_pigtail_list:connectionData.sockets,
            equipment_id:id,
            connection_equipment_id:connectionData.eqId
        }

        await axiosInstance.put(url,data)
            .then(data => setPorts(data))
            .catch(e => console.log(e))
    }

    return (
        <>
            <div className='patch-panel'>
                <p className='patch-panel__title'>{!data ? 
                    'Fetching data' : 
                    `${data.data.template.manufacturer} ${data.data.template.model}`}
                </p>
                <div 
                    className='patch-panel__wrapper' 
                    style={!ports ? 
                            {} : 
                            {
                                gridTemplateRows:`repeat(${data.data.template.number_of_units},1fr)`,
                                gridTemplateAreas:generateTemplateAreas(data.data.count_port_template_dict)
                            }
                    }
                >
                    {!ports ? 
                        'Fetching data' : 
                        buildEquipmentStructure(data.data.count_port_template_dict)
                    }
                </div>
                <div className='patch-panel__detailed'>
                    <div className='patch-panel__detailed__header'>
                        <p>№</p>
                        <p>Тип</p>
                        <p>Скорость</p>
                        <p>Vlan</p>
                        <p>IP</p>
                        <p>Mac</p>
                        <p>Подключение</p>
                    </div>
                    <div className='patch-panel__detailed__body'>
                        {!ports ? 
                            '' : 
                            ports.data.map(item => (
                                <div className="patch-panel__detailed__body__note">
                                    <p>{item.uid}</p>
                                    <p>{item.type.name ? item.type.name : '[SFP]'}</p>
                                    <p>{item.sfp ? item.sfp.speed[0] : `${item.speed[0]}/${item.speed[1]}`}</p>
                                    <p>{item.vlan ? item.vlan : '-/-'}</p>
                                    <p>{item.ip ? item.ip : '-/-'}</p>
                                    <p>{item.mac ? item.mac : '-/-'}</p>
                                    <p>{Object.keys(item.connection_pigtail).length > 0 ? <Info data={item.connection_pigtail} /> : '-/-'}</p>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
            <Modal
                active={modalActive}
                setActive={setModalActive}
                action={action}
                getResult={connectSockets}
            />
        </>
    )
}

export default PatchPanel