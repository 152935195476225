import './NavigationLink.css'
import icons from '../../../icons/icons'
import { useNavigate } from 'react-router-dom'

const NavigationLink = function({index, to, title, current, toHomepage, isCurrent}) {
    const navigate = useNavigate()

    const doNothing = function() {}

    return (
        <div 
            className={isCurrent ? 'navigation-link navigation-link--current' : 'navigation-link'} 
            style={{zIndex:`${1000 - index}`,paddingLeft:`${title === 'Главная' ? '5px' : '14px'}`}}
            onClick={() => {
                navigate(to)
                title === 'Главная' ? toHomepage() : doNothing()
            }}
        >
            {title === 'Главная' ? icons.house : ''}
            {title}
            <div className='navigation-link__arrow' style={{zIndex:`${1000 - index}`}}></div>
        </div>
    )
}

export default NavigationLink