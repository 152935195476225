import './Cell.css'
import icons from '../../../icons/icons'
import { useNavigate } from 'react-router-dom'

const Cell = function({ id,type,deviceType,units,length,totalUnits,title,delEquipment}) {
    const navigate = useNavigate()

    const edit = function() {
        if (deviceType === 'Коммутатор') {
            navigate(`/device/${id}`,{state:{description:`${title}`}})
        }
        else if (deviceType === 'Пассивное оборудование') {
            navigate(`/patch-panel/${id}`,{state:{description:`${title}`}})
        }
     }

    const deleteEquipment = function() {
        delEquipment(Number(id))
    }

    return(
        <>
            {type === 'placeholder' ? 
                <div 
                    className='placeholder'
                >
                    
                </div> : 
                <div 
                    className='equipment'
                    style={{height:`${units * (length/totalUnits)}px`}}
                >
                    {title}
                    <div className="equipment__delete" onClick={deleteEquipment}>{icons.squareMinus}</div>
                    <div className="equipment__edit" onClick={edit}>{icons.editing}</div>
                </div>
            }
        </>
    )
}

export default Cell