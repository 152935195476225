import './homePage.css'
import Dashboard from '../dashboard/dashboard';

const HomePage = function(props) {
    return(
        <div className={!props.navbarHidden ? 'homepage' : 'homepage homepage--blurred'}>
            <Dashboard />
        </div>
    );
}

export default HomePage;