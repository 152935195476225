import './cabinets.css'
import icons from '../../icons/icons';
import Cabinet from '../Cabinet/cabinet';
import { useParams, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import axios from 'axios';
import Modal from '../Modal/Modal';
import { toast } from 'sonner';

const Cabinets = function(props) {
    const {id,status} = useParams()
    const [cabinets,setCabinets] = useState(null)
    const [modalActive,setModalActive] = useState(false)
    const [action,setAction] = useState('addCabinet')
    const [cabinetId,setCabinetId] = useState(null)
    const navigate = useNavigate()

    const url = '/core_api/room/'

    const axiosInstance = axios.create({
        headers: {
          'Content-Type': 'application/json',
          'Authorization':`Token ${localStorage.getItem('authToken')}`
        }
    })

    useEffect(() => {
        axiosInstance.get(url,{params:{filter_building_id:id}})
            .then(data => setCabinets(data.data.results))
            .catch(e => console.log(e))
    }, [])

    const goBack = () => navigate(-1)

    const showModal = function() {
        setModalActive(true)
    }

    const addCabinet = async function(number,type) {
        let data = {
            building_id:Number(id),
            number:number,
            type:type
        }

        let updateCabinets = function(data) {
            setCabinets([...cabinets,{id:data.data.id,number:number,type:type,count_free_socket:0}])
            toast.success(
                <div className='toast-message--success'>{icons.success} Помещение {data.data.number} было успешно создано!</div>,
                {duration:5000,position:'bottom-left'}
            )
        }

        await axiosInstance.post(url,data)
            .then(data => updateCabinets(data))
            .catch(e => console.log(e))
    }

    const confirmDeletion = function(id) {
        setCabinetId(id)
        setAction('delCabinet')
        setModalActive(true)
    }

    const deleteCabinet = async function(state) {
        if (state === true) {
            let url = `/core_api/room/${cabinetId}/`
            let cabName = cabinets.find(item => item.id === cabinetId).number

            let updateCabinets = function() {
                setCabinets(cabinets => cabinets.filter(item => item.id !== cabinetId))
                setAction('addCabinet')
                setCabinetId(null)
                toast.success(
                    <div className='toast-message--success'>{icons.success} Помещение {cabName} было успешно удалено!</div>,
                    {duration:5000,position:'bottom-left'}
                )
            }

            await axiosInstance.delete(url)
                .then(data => updateCabinets())
                .catch(e => console.log(e))
        }
        else {
            setAction('addCabinet')
            setCabinetId(null)
        }
    }

    return(
        <div className={!props.navbarHidden ? 'cabinets' : 'cabinets cabinets--blured'}>
            <p className='cabinets__title'>{`Помещения:`}</p>
            <div className='cabinets__wrapper'>
                <div className='cabinets__wrapper__add-new' onClick={showModal}>
                    {icons.cabinets.plus}
                    <p className='cabinets__wrapper__add-new__title'>Добавить</p>
                </div>
                {!cabinets ? '' : cabinets.map(elem => (
                    <Cabinet 
                        id={elem.id} 
                        name={elem.number} 
                        type={elem.type}
                        delCabinet={confirmDeletion}
                    />
                ))}
            </div>
            <Modal
                active={modalActive}
                setActive={setModalActive}
                action={action}
                getResult={action === 'addCabinet' ? addCabinet : deleteCabinet}
            />
        </div>
    );
}

export default Cabinets;