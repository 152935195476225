import './CustomCheckBox.css'
import { useState } from 'react'

const CustomCheckBox = function({title,style,value,getValue,blocked}) {
    const [checked,setChecked] = useState(value)

    const toggleChecked = function() {
        if (checked === false) {
            setChecked(true)
            getValue(true)
        }
        else {
            setChecked(false)
            getValue(false)
        }
    }

    return (
        <div 
            className={blocked === false ? 'custom-checkbox' : 'custom-checkbox custom-checkbox--blocked'} 
            style={style} 
            onClick={toggleChecked}
        >
            {(value ? value : checked) ? 
                <svg xmlns="http://www.w3.org/2000/svg" className='custom-checkbox__icon' viewBox="0 0 448 512"><path d="M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zM337 209L209 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L303 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z"/></svg> : 
                <svg xmlns="http://www.w3.org/2000/svg" className='custom-checkbox__icon' viewBox="0 0 448 512"><path 
                    d="M64 80c-8.8 0-16 7.2-16 16V416c0 8.8 7.2 16 16 16H384c8.8 0 16-7.2 16-16V96c0-8.8-7.2-16-16-16H64zM0 96C0 60.7 28.7 32 64 32H384c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96zM337"
                /></svg>
            } {title}
        </div>
    )
}

export default CustomCheckBox