import './CustomSelect.css'
import { useState,useRef,useEffect } from 'react'
import icons from '../../icons/icons'
import axios from 'axios'

const CustomSelect = function({ style,subStyle,placeholder,editable,target,options,value,getValue }) {
    const [selection,setSelection] = useState(value ? value : placeholder)
    const [showDropdown,setShowDropdown] = useState(false)
    const [showField,setShowField] = useState(false)
    const [opt,setOpt] = useState(options)

    const axiosInstance = axios.create({
        headers: {
          'Content-Type': 'application/json',
          'Authorization':`Token ${localStorage.getItem('authToken')}`
        }
    })

    const rootRef = useRef(null)
    const inputRef = useRef(null)

    useEffect(() => {
        function handleClickOutside(event) {
          if (rootRef.current && !rootRef.current.contains(event.target)) {
            setShowDropdown(false)
          }
        }
    
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
        
      }, []);

    const toggleDropdown = function() {
        setShowDropdown(prevValue => (!prevValue))
    }

    const select = function(event) {
        if (event.target.classList.contains("custom-select__option")) {
            setSelection(event.target.innerHTML)
            getValue(event.target.innerHTML)
            toggleDropdown()
        }
    }

    const addOption = async function() {
        let url = `/core_api/${target}/`

        let data = {
            name:inputRef.current.value
        }

        await axiosInstance.post(url,data)
            .then(data => setOpt(prevValue => [...prevValue,data.data.name]))
            .catch(e => console.log(e))

        setShowField(false)
    }

    const cancelAddOption = function() {
        inputRef.current.value = ''
        setShowField(false)
    }

    return(
        <div className='custom-select' style={style} ref={rootRef}>
            <div className="custom-select__field" style={subStyle} onClick={toggleDropdown}>
                {value ? value : selection}
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M137.4 41.4c12.5-12.5 32.8-12.5 45.3 0l128 128c9.2 9.2 11.9 22.9 6.9 34.9s-16.6 19.8-29.6 19.8H32c-12.9 0-24.6-7.8-29.6-19.8s-2.2-25.7 6.9-34.9l128-128zm0 429.3l-128-128c-9.2-9.2-11.9-22.9-6.9-34.9s16.6-19.8 29.6-19.8H288c12.9 0 24.6 7.8 29.6 19.8s2.2 25.7-6.9 34.9l-128 128c-12.5 12.5-32.8 12.5-45.3 0z"/></svg>
            </div>
            <div className={showDropdown === false ? "custom-select__dropdown" : "custom-select__dropdown custom-select__dropdown--active"} onClick={select}>
                {editable ? !showField ? 
                    <div className='custom-select__dropdown__add-new' 
                        onClick={() => {
                            setShowField(prevValue => !prevValue)
                    }}>
                    {icons.squarePlus}Добавить
                    </div> : 
                    <div className='custom-select__dropdown__add-new__form'>
                        <input ref={inputRef} type='text'></input>
                        <div className='custom-select__dropdown__add-new__form__button--add' onClick={addOption}>
                            {icons.check}
                        </div>
                        <div className='custom-select__dropdown__add-new__form__button--cancel' onClick={cancelAddOption}>
                            {icons.crest}
                        </div>
                    </div> : ''
                    
                }
                {opt.length > 0 ? opt.map(item => (
                    <p className='custom-select__option' style={subStyle}>{item}</p>
                )) : options.map(item => (
                    <p className='custom-select__option' style={subStyle}>{item}</p>
                ))}
            </div>
        </div>
    )
}

export default CustomSelect