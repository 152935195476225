import { useState, useEffect } from 'react'
import './RackDetailed.css'
import { DndContext,PointerSensor,closestCenter,useSensor } from '@dnd-kit/core'
import { SortableContext, arrayMove, arraySwap, verticalListSortingStrategy } from '@dnd-kit/sortable'
import Cell from '../Cell/Cell'
import Modal from '../../Modal/Modal'
import axios from 'axios'
import { useParams, useNavigate } from 'react-router-dom'
import icons from '../../../icons/icons'
import Equipment from '../../Equipment/Equipment'
import { toast } from 'sonner'

const RackDetailed = function({name,length}) {
    const [modalActive,setModalActive] = useState(false)
    const [showDevices,setShowDevices] = useState(true)
    const [rackData,setRackData] = useState(null)
    const [equipmentData,setEquipmentData] = useState(null)
    const [side,swapSide] = useState('Лицевая')
    const [draggingElement,setDraggingElement] = useState(null)
    const [aboveTarget,setAboveTarget] = useState(false)
    const [targetId,setTargetId] = useState(null)
    const [action,setAction] = useState('addEquipmentTemplate')
    const [equipmentId,setEquipmentId] = useState(null)
    const [templateId,setTemplateId] = useState(null)

    const {id} = useParams()
    const navigate = useNavigate()
    const goBack = function() {
        navigate(-1)
    }

    const url = `/core_api/server_rack/${id}/`
    const url1 = `/core_api/equipment_template/`

    const axiosInstance = axios.create({
        headers: {
            'Content-Type': 'application/json',
            'Authorization':`Token ${localStorage.getItem('authToken')}`
        }
    })

    useEffect(() => {
        async function fetchRackData() {
            await axiosInstance.get(url)
            .then(data => setRackData(data.data))
            .catch(e => console.log(e))
        }
        async function fetchEquipmentData() {
            await axiosInstance.get(url1)
            .then(data => setEquipmentData(data.data.results))
            .catch(e => console.log(e))
        }
        fetchRackData()
        fetchEquipmentData()
    }, [])

    const rotateRack = function() {
        if (side === 'Лицевая') {
            swapSide('Тыльная')
        }
        else {
            swapSide('Лицевая')
        }
    }

    const showModal = function() {
        setModalActive(true)
    }

    const toggleShowDevices = function() {
        setShowDevices(prevValue => !prevValue)
    }

    const sensors = [useSensor(PointerSensor)]

    const handleDragEnter = function(e) {
        setAboveTarget(true)
        setTargetId(Number(e.target.getAttribute('id')))
    }

    const handleDragLeave = function(e) {
        if (!e.target.classList.contains('droppable')) {
            setAboveTarget(false)
            setTargetId(null)
        }
    }

    const handleDragEnd = async function() {
        function getUnits() {
            let arr = []
            for (let i = targetId;i < targetId + draggingElement.units;i++) {
                arr.push(rackData.units.find(item => item.uid === i && item.side === side).id)
            }
            
            return arr
        }

        await axiosInstance.post('/core_api/equipment/', {
            equipment_template_id: draggingElement.id,
            unit_list_id: getUnits(),
            server_rack_id: id
        })
            .then(data => setRackData(data.data))
            .catch(e => console.log(e));
    }

    const setHighlight = function(e) {
        e.target.classList.add('droppable--highlight')
    }

    const removeHighlight = function(e) {
        e.target.classList.remove('droppable--highlight')
    }

    const parseUnits = function(data) {
        let newArr = []

        for (let i = 0; i < data.length;i++) {
            if (data[i].equipment) {
                let countLength = 1

                for (let j = i;j < data.length;j++) {
                    if (data[j+1] && (data[j].equipment && data[j+1].equipment) && data[j].equipment.id === data[j+1].equipment.id) {
                        countLength += 1
                    }
                    else {
                        break
                    }
                }

                newArr.push(
                <Cell
                    id={data[i].equipment.id}
                    type={'equipment'}
                    deviceType={data[i].equipment.type}
                    units={countLength}
                    length={document.getElementById('rack-detailed__body__flow').clientHeight}
                    totalUnits={data.length}
                    title={`${data[i].equipment.manufacturer} ${data[i].equipment.model}`}
                    delEquipment={confirmDeletion}
                />)
                i += countLength - 1
            }
            else {
                newArr.push(
                <div
                    id={data[i].uid} 
                    onDragEnter={setHighlight} 
                    onDragLeave={removeHighlight}
                    style={{height:`${document.getElementById('rack-detailed__body__flow').clientHeight/data.length}px`}}
                    className={`droppable ${side === 'Лицевая' ? 'front' : 'rear'}`}
                >
                </div>)
            }
        }
        return newArr
    }

    const updateDevices = function() {
        function updt(data) {
            setEquipmentData(data.data.results)
            toast.success(
                <div className='toast-message--success'>{icons.success} Шаблон оборудования был успешно добавлен!</div>,
                {duration:5000,position:'bottom-left'}
            )
        }

        async function fetchEquipmentData() {
            await axiosInstance.get(url1)
            .then(data => updt(data))
            .catch(e => console.log(e))
        }
        fetchEquipmentData()
    }

    const confirmDeletion = function(id) {
        setEquipmentId(id)
        setAction('delEquipment')
        setModalActive(true)
    }

    const deleteEquipment = async function(state) {
        if (state === true) {
            let url = `/core_api/equipment/${equipmentId}/`

            let targetEq = rackData.units.find(item => item.equipment && item.equipment.id === equipmentId).equipment
            let eqName = `${targetEq.manufacturer} ${targetEq.model}`

            let updateEquipment = function() {
                let arr = rackData.units

                let clearEq = function(item) {
                    return {...item,equipment:null}
                }

                arr = arr.map(item => item.equipment && item.equipment.id === equipmentId ? clearEq(item) : item)

                setRackData({...rackData,units:arr})

                setAction('addEquipmentTemplate')
                setEquipmentId(null)
                toast.success(
                    <div className='toast-message--success'>{icons.success} Оборудование {eqName} было успешно удалено!</div>,
                    {duration:5000,position:'bottom-left'}
                )
            }

            await axiosInstance.delete(url)
                .then(data => updateEquipment())
                .catch(e => console.log(e))
        }
        else {
            setEquipmentId(null)
            setAction('addEquipmentTemplate')
        }
    }

    const checkUnitsEquipment = function(id) {
        if (rackData.units.find(item => item.equipment && item.equipment.equipment_id === id)) {
            return true
        }
        else {
            return false
        }
    }

    const confirmTemplateDeletion = function(id) {
        if (checkUnitsEquipment(id) === true) {
            toast.error(
                <div className='toast-message--error'>{icons.error} Оборудование выбранного шаблона размещено в стойке!</div>,
                {duration:5000,position:'bottom-left'}
            )
        }
        else {
            setTemplateId(id)
            setAction('delEquipmentTemplate')
            setModalActive(true)
        }
    }

    const deleteEquipmentTemplate = async function(state) {
        if (state === true) {
            let url = `/core_api/equipment_template/${templateId}/`
            
            let targetTemp = equipmentData.find(item => item.id === templateId)
            let tempName = `${targetTemp.manufacturer.name} ${targetTemp.model}`

            let updateEquipmentData = function() {
                setEquipmentData(equipmentData => equipmentData.filter(item => item.id !== templateId))
                setTemplateId(null)
                setAction('addEquipmentTemplate')
                toast.success(
                    <div className='toast-message--success'>{icons.success} Шаблон оборудования {tempName} был успешно удален!</div>,
                    {duration:5000,position:'bottom-left'}
                )
            }

            await axiosInstance.delete(url)
                .then(data => updateEquipmentData())
                .catch(e => console.log(e))
        }
        else {
            setTemplateId(null)
            setAction('addEquipmentTemplate')
        }
    }

    return(
        <div className='rack-interface' onDragLeave={handleDragLeave}>
            <div className={showDevices ? 'devices' : 'devices devices--hidden'}>
                <div className='devices__show' onClick={toggleShowDevices}>
                    {showDevices ? icons.arrowRight : icons.arrowLeft}
                </div>
                <div className='devices__body'>
                    <div className='devices__body__title'>
                        Оборудование
                        <div className='devices__body__title__add-new' onClick={showModal}>{icons.plus}</div>
                    </div>
                    <div className='devices__body__wrapper'>
                        {!equipmentData ? [] : equipmentData.map(elem => (
                            <Equipment 
                                id={elem.id}
                                type={elem.type} 
                                manufacturer={elem.manufacturer.name} 
                                model={elem.model} 
                                units={elem.number_of_units} 
                                power={elem.power}
                                ports={elem.count_port}
                                aboveTarget={aboveTarget}
                                passDraggingElement={setDraggingElement}
                                addEquipment={handleDragEnd}
                                delEquipment={confirmTemplateDeletion}
                            />
                        ))}
                    </div>
                </div>
            </div>
            <div 
                className={showDevices ? 'rack-detailed' : 'rack-detailed rack-detailed--without-devices'}
            >
                <p className='rack-detailed__title'>
                    <p className='rack-detailed__title__side'>{side && side === 'Лицевая' ? 'F' : 'R'}</p>
                    {!rackData ? '' : rackData.title}
                    {<svg className="rack-detailed__title__rotate" onClick={rotateRack} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M142.9 142.9c62.2-62.2 162.7-62.5 225.3-1L327 183c-6.9 6.9-8.9 17.2-5.2 26.2s12.5 14.8 22.2 14.8H463.5c0 0 0 0 0 0H472c13.3 0 24-10.7 24-24V72c0-9.7-5.8-18.5-14.8-22.2s-19.3-1.7-26.2 5.2L413.4 96.6c-87.6-86.5-228.7-86.2-315.8 1C73.2 122 55.6 150.7 44.8 181.4c-5.9 16.7 2.9 34.9 19.5 40.8s34.9-2.9 40.8-19.5c7.7-21.8 20.2-42.3 37.8-59.8zM16 312v7.6 .7V440c0 9.7 5.8 18.5 14.8 22.2s19.3 1.7 26.2-5.2l41.6-41.6c87.6 86.5 228.7 86.2 315.8-1c24.4-24.4 42.1-53.1 52.9-83.7c5.9-16.7-2.9-34.9-19.5-40.8s-34.9 2.9-40.8 19.5c-7.7 21.8-20.2 42.3-37.8 59.8c-62.2 62.2-162.7 62.5-225.3 1L185 329c6.9-6.9 8.9-17.2 5.2-26.2s-12.5-14.8-22.2-14.8H48.4h-.7H40c-13.3 0-24 10.7-24 24z"/></svg>}
                </p>
                <div className='rack-detailed__body'>
                    {!rackData ? '' : rackData.units.filter((elem) => (elem.side === side)).map(elem => (<div className={`droppable ${side === 'Лицевая' ? 'front' : 'rear'}`} style={{height:`${document.getElementById('rack-detailed__body__flow').clientHeight/rackData.units.filter((elem) => (elem.side === side)).length}px`}}>{elem.uid}</div>))}
                    <div 
                        id='rack-detailed__body__flow' className='rack-detailed__body__flow' 
                        onDragEnter={handleDragEnter}
                        onDragLeave={handleDragLeave}
                        onDragEnd={handleDragEnd}
                    >
                        {!rackData ? '' : parseUnits(rackData.units.filter((elem) => (elem.side === side)))}
                    </div>
                </div>
                <Modal 
                    active={modalActive} 
                    setActive={setModalActive} 
                    action={action}
                    axiosInstance={axiosInstance}
                    getResult={action === 'addEquipmentTemplate' ? updateDevices : action === 'delEquipment' ? deleteEquipment : deleteEquipmentTemplate}
                />
            </div>
        </div>
        
    );
}

export default RackDetailed;