import { useState,useEffect } from 'react'
import icons from '../../icons/icons';
import './rack.css'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const Rack = function({id,name,delRack}) {
    const navigate = useNavigate()

    const url = `/core_api/server_rack/${id}/`

    const axiosInstance = axios.create({
        headers: {
          'Content-Type': 'application/json',
          'Authorization':`Token ${localStorage.getItem('authToken')}`
        }
    })

    const [equipmentCount,setEquipmentCount] = useState(null)

    useEffect(() => {
        const fetchRackData = async function() {
            await axiosInstance.get(url)
                .then(data => setEquipmentCount(new Set(data.data.units.filter(item => item.equipment).map(item => item.equipment.id)).size))
                .catch(e => console.log(e))
        }
        fetchRackData()
    }, [])

    const handleClick = function(e) {
        if (e.target.classList.contains('rack')) {
            navigate(`/rack/${id}`,{state:{description:`${name}`}})
        }
        else if (e.target.classList.contains('rack__delete')) {
            delRack(Number(id))
        }
    }

    return(
        <div className='rack' onClick={handleClick}>
            <div className="rack__delete">{icons.squareMinus}</div>
            {icons.server_rack}
            <p className='rack__title'>{name}</p>
            <div className="rack__equipment-count">{equipmentCount ? equipmentCount : '0'}</div>
        </div>
    );
}

export default Rack;