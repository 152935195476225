import './schemes.css'
import Scheme from '../Scheme/scheme';
import Modal from '../Modal/Modal';
import { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import icons from '../../icons/icons';
import { toast } from 'sonner';

const Schemes = function(props) {
    const url = '/core_api/scheme/'

    const [schemes,setSchemes] = useState(null)
    const [modalActive,setModalActive] = useState(false)
    const [action,setAction] = useState('addScheme')
    const [deletionApprove,setDeletionApprove] = useState(null)
    const [schemeId,setSchemeId] = useState(null)

    const confirmDeletion = async function(state) {
        if (state === true) {
            let url = `/core_api/scheme/${schemeId}/`
            let schemeName = schemes.find(item => item.id === schemeId).title

            function updateSchemes(id) {
                setSchemes(schemes => schemes.filter(item => item.id !== id))
                toast.success(
                    <div className='toast-message--success'>{icons.success} Схема {schemeName} была успешно удалена!</div>,
                    {duration:5000,position:'bottom-left'}
                )
            }

            function deleteScheme() {
                axiosInstance.delete(url)
                    .then(data => updateSchemes(schemeId))
                    .catch(e => console.log(e))
            }

            await deleteScheme()
            await setAction('addScheme')
            await setSchemeId(null)
        }
        else {
            await setAction('addScheme')
            await setSchemeId(null)
        }
    }

    const removeScheme = async function(id) {
        await setSchemeId(id)
        await setAction('deleteScheme')
        await setModalActive(true)
    }

    const axiosInstance = axios.create({
        headers: {
          'Content-Type': 'application/json',
          'Authorization':`Token ${localStorage.getItem('authToken')}`
        }
    })

    useEffect(() => {
        async function fetchData() {
            await axiosInstance.get(url)
                .then(data => setSchemes(data.data))
                .catch(e => console.log(e))
        }

        fetchData()
    }, [])

    
    function parseData(data) {
        return data.map((scheme) => (<Scheme id={scheme.id} author={scheme.creator} name={scheme.title} removeScheme={removeScheme}/>))
    }

    const cachedData = useMemo(() => {
        if (!schemes) return null
        return parseData(schemes)
    }, [schemes])

    const createNewScheme = function() {
        setModalActive(true)
    }

    const updateSchemes = function(id,title) {
        setSchemes([...schemes,{id:id,title:title}])
        toast.success(
            <div className='toast-message--success'>{icons.success} Схема {title} была успешно создана!</div>,
            {duration:5000,position:'bottom-left'}
        )
    }

    return(
        <div className={!props.navbarHidden ? 'schemes' : 'schemes schemes--blured'}>
            <div className='schemes__add-new' onClick={createNewScheme}>{icons.plus}Создать схему</div>
            {!cachedData ? '' : cachedData}
            <Modal
                active={modalActive}
                setActive={setModalActive}
                action={action}
                axiosInstance={axiosInstance}
                getResult={action === 'addScheme' ? updateSchemes : confirmDeletion}
            />
        </div>
    );
}

export default Schemes;