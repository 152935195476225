import { createContext, useState } from "react";

export const AuthContext = createContext(null)

export const AuthProvider = function({children}) {
    const [user,setUser] = useState(null)

    const signin = function(newUser,cb) {
        setUser(newUser)
        cb()
    }
    const signout = function(cb) {
        setUser(null)
        localStorage.removeItem('authToken')
        cb()
    }

    const value = {user, signin, signout}

    return <AuthContext.Provider value={value}>
        {children}
    </AuthContext.Provider>
}