import './SocketGroup.css'
import CustomSelect from '../../subComponents/CustomSelect/CustomSelect'
import CustomCheckBox from '../../subComponents/CustomCheckBox/CustomCheckBox'
import { useState,useEffect } from 'react'
import icons from '../../icons/icons'

const SocketGroup = function({id,data,onDelete,passGroupData,axiosInstance}) {
    const url = '/core_api/type_port/'

    const deleteSection = function() {
        onDelete(id)
    }

    const [socketGroup,setSocketGroup] = useState(data)
    const [port_types,setPortTypes] = useState([])
    const [lines,setLines] = useState('repeat(1,1fr)')

    useEffect(() => {
        async function fetchPortTypes() {
            await axiosInstance.get(url)
            .then(data => setPortTypes(data.data))
            .catch(e => console.log(e))
        }
        fetchPortTypes()
    }, [])

    const handleInputUnit = function(value) {
        setSocketGroup({...socketGroup,unit:value})
        passGroupData(id,'unit',{unit:value})
    }

    const handleInputName = function(e) {
        setSocketGroup({...socketGroup,name:e.target.value})
        passGroupData(id,'name',{name:e.target.value})
    }

    const handleInputCount = function(e) {
        setSocketGroup({...socketGroup,count:e.target.value})
        passGroupData(id,'count',{count:e.target.value})
    }

    const handleInputSpeed = function(value) {
        let arr = []
        value.split('-').map(item => (arr.push(Number(item))))
        setSocketGroup({...socketGroup,speed:arr})
        passGroupData(id,'speed',{speed:arr})
    }

    const handleInputType = function(value) {
        setSocketGroup({...socketGroup,type_port:value})
        passGroupData(id,'type_port',{type_port:value})
    }

    const handleInputMode = function(value) {
        setSocketGroup({...socketGroup,modular:value})
        passGroupData(id,'type_port',{modular:value === true ? null : (data.type_port ? data.type_port : null)})
        passGroupData(id,'modular',{modular:value})
    }

    const handleInputLines = function(value) {
        if (value === 'В один ряд') {
            setLines('repeat(1,1fr)')
            setSocketGroup({...socketGroup,lines:1})
            passGroupData(id,'lines',{lines:1})
        }
        else {
            setLines('repeat(2,1fr)')
            setSocketGroup({...socketGroup,lines:2})
            passGroupData(id,'lines',{lines:2})
        }
    }

    const spawnSockets = function(count) {
        let arr = []
        for (let i = 0;i < count;i++) {
            arr.push(i)
        }

        return arr.map(item => <div className="socket-group__preview__inner-grid__socket"></div>)
    }

    return (
        <div className='socket-group'>
            <CustomSelect 
                style={{gridArea:'unit'}}
                subStyle={{fontSize:'1.5rem'}}
                placeholder={'<Юнит>'} 
                options={['1','2','3','4']} 
                value={data.unit ? data.unit : null}
                getValue={handleInputUnit}
            />
            <CustomSelect 
                style={{gridArea:'lines'}}
                subStyle={{fontSize:'1.5rem'}}
                placeholder={'<Размещение>'} 
                options={['В один ряд','В два ряда']}
                getValue={handleInputLines}
            />
            <input type="number" min={0} max={40} step={1} placeholder='<Кол-во>' value={data.count ? data.count : ''} onChange={handleInputCount} style={{gridArea:'count'}}/>
            <div className="socket-group__preview" style={{gridArea:'preview'}}>
                <div 
                    className="socket-group__preview__inner-grid"
                    style={{
                        gridTemplateRows:lines,
                        gridTemplateColumns:lines === 'repeat(1,1fr)' ? 
                            `repeat(${Number(socketGroup.count)},1fr)` : 
                            `repeat(${Math.ceil(Number(socketGroup.count)/2)},1fr)`
                    }}
                >
                    {socketGroup.count ? spawnSockets(Number(socketGroup.count)) : null}
                </div>
            </div> 
            <CustomCheckBox 
                style={{fontSize:'1.5rem',gridArea:'modular'}} 
                title='Модульный'
                value={data.modular ? data.modular : false}
                getValue={handleInputMode}
                blocked={data.type_port && data.type_port === 'RJ-45' ? true : false}
            />
            {
                data.modular ? 
                    '' : 
                    <CustomSelect 
                        style={{gridArea:'type'}}
                        subStyle={{fontSize:'1.5rem'}}
                        placeholder={'<Тип>'}
                        options={port_types.map(item => item.name)}
                        value={data.type_port ? data.type_port : null}
                        getValue={handleInputType}
                    />
            }
            <CustomSelect 
                style={{gridArea:'speed'}}
                subStyle={{fontSize:'1.5rem'}}
                placeholder={'<Скорость>'} 
                options={['10-100','100-1000','10-100-1000']} 
                value={data.speed ? data.speed.join('-') : null}
                getValue={handleInputSpeed}
            />
            <input type="text" placeholder='<Примечание>' value={data.name ? data.name : ''} onChange={handleInputName} style={{gridArea:'other'}}/>
            <svg className='socket-group__delete' onClick={deleteSection} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z"/></svg>
        </div>
    )
}

export default SocketGroup